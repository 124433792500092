<script>

import InputFeedbackMessage from '@/components/InputFeedbackMessage.vue';

export default {
  name: 'ProfessionalEdit',
  components: { InputFeedbackMessage },
  data: () => ({
    isTwilioPhoneNumberValid: true,
    user: {
      id: null,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      external_phone_number: null,
      username: '',
      clinical_assistants: [],
      extra_roles: [],
      intake_flow: false,
      treatment_flow: true,
      lead_id: '',
      move_configuration: null,
      unit: null,
    },
  }),
  computed: {},
  beforeMount: () => {},
  methods: {},
};
</script>

<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h5>Edit Professional</h5>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-tabs card pills vertical>
        <b-tab title="Personal">
          <b-card-text>
            <b-row>
                <b-col cols="6">
                  <b-form-group label="Username" label-for="username">
                    <b-form-input id="username" v-model="user.username" disabled/>
                  </b-form-group>
                  <b-form-group label="ACL UUID" label-for="acl_uuid">
                    <b-form-input id="acl_uuid" v-model="user.acl_uuid" disabled/>
                  </b-form-group>
                  <b-form-group label="Email" label-for="email">
                    <b-form-input id="email" v-model="user.email" :disabled="!$acl.check('users.edit')" type="email" />
                  </b-form-group>
                  <b-form-group label="First name" label-for="firstname">
                    <b-form-input id="firstname" v-model="user.firstname" :disabled="!$acl.check('users.edit')" />
                  </b-form-group>
                  <b-form-group label="Last name" label-for="lastname">
                    <b-form-input id="lastname" v-model="user.lastname" :disabled="!$acl.check('users.edit')" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone" label-for="phone">
                    <b-form-input id="phone" v-model="user.telephone" :disabled="!$acl.check('users.edit')" />
                  </b-form-group>
                  <b-form-group label="Twilio Phone Number" label-for="externalPhoneNumber">
                    <b-form-input id="externalPhoneNumber" v-model="user.external_phone_number"
                                  :disabled="!$acl.check('users.edit')" type="tel" />
                  </b-form-group>
                  <InputFeedbackMessage v-if="!isTwilioPhoneNumberValid" class="text-danger"
                                        data-testid="userPhoneInputMessageSMSComplaint" icon-name="alert-triangle"
                                        message="This Phone Number is not eligible for SMS" />
                  <b-form-group label="Metric System" label-for="metricSystem">
                    <b-form-select id="metricSystem" v-model="user.measurement_system"
                                   :disabled="!$acl.check('users.edit')" required>
                      <b-form-select-option value="metric">Metric</b-form-select-option>
                      <b-form-select-option value="imperial">Imperial</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="NPI" label-for="npi">
                    <b-form-input id="npi" v-model="user.npi" :disabled="!$acl.check('users.edit')" maxlength="10" />
                  </b-form-group>
                </b-col>
            </b-row>
            <hr/>
            <b-row>
              <b-col cols="12">
                <b-button variant="primary" class="float-right">Save personal information</b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Therapist">
          <b-card-text>
            <b-row>
              <b-col cols="12">
                tal e coiso, pardais ao ninho
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Tags">
          <b-card-text>
            <b-row>
              <b-col cols="12">
                tal e coiso, pardais ao ninho
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Capacity">
          <b-card-text>
            <b-row>
              <b-col cols="12">
                tal e coiso, pardais ao ninho
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Licenses">
          <b-card-text>
            <b-row>
              <b-col cols="12">
                tal e coiso, pardais ao ninho
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Credentials">
          <b-card-text>
            <b-row>
              <b-col cols="12">
                tal e coiso, pardais ao ninho
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>


      </b-tabs>
    </b-card>
  </b-container>
</template>
